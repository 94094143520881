<template>
  <div class="v-stack h-stretch gap-3" v-if="user">
    <a class="heading-title-1">{{ user.firstName + " " + user.lastName }}</a>
    <div class="h-stack h-start pb-3 gap-3">
      <router-link :to="baseURL + '/overview'">Overview</router-link>
      <router-link v-if="$store.state.accessLevel > 9 || user._id == $store.state.id" :to="baseURL + '/hours'">Hours
      </router-link>
      <router-link v-if="$store.state.accessLevel > 9 || user._id == $store.state.id" :to="baseURL + '/loans'">Company
        Loans</router-link>
      <router-link v-if="$store.state.accessLevel > 9 || user._id == $store.state.id" :to="baseURL + '/vacation'">
        Vacation</router-link>
      <router-link v-if="$store.state.accessLevel > 9 || user._id == $store.state.id" :to="baseURL + '/pay'">Pay
      </router-link>
    </div>
    <router-view></router-view>
    <template v-if="$store.state.accessLevel > 9 || user._id == $store.state.id">
      <template v-if="user.accessLevel == 0">
        <a class="heading-title-2">Fixed days</a>
        <div class="table">
          <div class="table-header pay-row">
            <div>Project</div>
            <div>Role</div>
            <div>Cost</div>
            <div>Gear Cost</div>
          </div>
          <div v-for="staff in user.staff.filter((element) => element.cost > 0)" :key="staff._id"
            class="table-row pay-row">
            <div>
              {{
                  staff.project
                    ? staff.project.title + " " + staff.project.subtitle
                    : staff.recordingDay.project.title +
                    " " +
                    staff.recordingDay.project.subtitle
              }}
            </div>
            <div>{{ staff.roles.join(", ") }}</div>
            <div>{{ format.currency(staff.cost) }} Kč</div>
            <div>{{ format.currency(staff.gearCost) }} Kč</div>
          </div>
          <div v-if="user.staff.filter((element) => element.cost > 0).length == 0" class="table-row">
            <div>No days</div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
const moment = require("moment");
import { mapActions } from "vuex";
import utils from "@/utils.js";

export default {
  data() {
    return {
      user: null,
      baseURL: "",
      format: utils.format,
    };
  },
  props: {
    id: {
      type: String,
      default: () => Vue.store.state.id,
    },
  },
  methods: {
    ...mapActions(["getUser", "signWorkdays", "signOneTimePay"]),
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    refresh() {
      this.baseURL = this.$router.currentRoute.path.startsWith("/account")
        ? "/account"
        : "/users/detail/" + this.id;

      this.getUser(this.id)
        .then((user) => {
          this.user = user;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
  watch: {
    id() {
      this.refresh();
    },
  },
  beforeRouteEnter(to, from, next) {
    from;

    next((vm) => {
      if (to.name) {
        vm.$router.push(to.path + "/overview");
      }
    });
  },
};
</script>
